<template>
  <div class="game-container">
    <PhoneNumberGame :callList="callList" />
  </div>
</template>

<script>
import PhoneNumberGame from "@/components/Course/GamePage/PhoneNumberGame";
export default {
  data() {
    return {
      callList: [
        {
          id: 1,
          sound: require("@/assets/audio/L1/6-Numbers/1-1-2.mp3"),
          number: "112",
          pinying: "yī yī èr",
          hanzi: "一一二",
          show: true,
          isPhone: true
        },
        {
          id: 2,
          sound: require("@/assets/audio/L1/6-Numbers/3-4-5.mp3"),
          number: "345",
          pinying: "sān sì wǔ",
          hanzi: "三四五",
          show: true,
          hasDialButton: true,
          showLock: true,
          isPhone: true
        },
        {
          id: 3,
          sound: require("@/assets/audio/L1/6-Numbers/1-2-3-4-5.mp3"),
          number: "12345",
          pinying: "yī èr sān sì wǔ",
          hanzi: "一二三四五",
          show: true,
          hasDialButton: true,
          isPhone: true
        },
      ],
    };
  },
  components: {
    PhoneNumberGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>


<template>
  <div class="game-container">
    <FindElementByPicGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="true"
    />
  </div>
</template>

<script>
import FindElementByPicGame from "@/components/Course/GamePage/FindElementByPicGame";
export default {
  data() {
    return {
      totalStars: 7,
      questionInfoList: [
        {
          id: 1,
          pinyin: "yī",
          hanzi: "一",
          sound:require('@/assets/audio/L1/5-Words/1.mp3'),
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg')
        },
        {
          id: 5,
          pinyin: "wǔ",
          hanzi: "五",
          sound:require('@/assets/audio/L1/5-Words/5.mp3'),
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg')
        },
        {
          id: 6,
          pinyin: "liù",
          hanzi: "六",
          sound:require('@/assets/audio/L1/5-Words/6.mp3'),
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg')
        },
        {
          id: 8,
          pinyin: "bā",
          hanzi: "八",
          sound:require('@/assets/audio/L1/5-Words/8.mp3'),
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg')
        },
        {
          id: 10,
          pinyin: "shí",
          hanzi: "十",
          sound:require('@/assets/audio/L1/5-Words/10.mp3'),
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg')
        },
         {
          id: 7,
          pinyin: "qī",
          hanzi: "七",
          sound:require('@/assets/audio/L1/5-Words/7.mp3'),
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg')
        },
        {
          id: 9,
          pinyin: "jiǔ",
          hanzi: "九",
          sound:require('@/assets/audio/L1/5-Words/9.mp3'),
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg')
        },
      ],
      asideElementInfo: {
        leftList: [
          {
          id: 1,
          element: 1,
        },
        {
          id: 2,
          element: 2,
        },
        {
          id: 3,
          element: 3,
        },
        {
          id: 4,
          element: 4,
        },
        {
          id: 5,
          element: 5,
        },
        ],
        rightList: [
          {
          id: 6,
          element: 6,
        },
        {
          id: 7,
          element: 7,
        },
        {
          id: 8,
          element: 8,
        },
        {
          id: 9,
          element: 9,
        },
        {
          id: 10,
          element: 10,
        },
        ],
      },
    };
  },
  components: {
    FindElementByPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <!-- layout-container lesson 5 -->
  <div class="lesson-one-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageA />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageA from "./3_SyllabusPage_A.vue";

import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import IntroductionPage from "./4_Introduction.vue";
import StoryPageOne from "./5_StoryPageOne.vue";
import RecognitionPageOne from "./6_RecognitionPage_liu.vue";
import RecognitionPageTwo from "./7_RecognitionPage_qi.vue";
import StoryPageTwo from "./8_StoryPageTwo.vue";
import RecognitionPageThree from "./9_RecognitionPage_ba.vue";
import RecognitionPageFour from "./10_RecognitionPage_jiu.vue";
import RecognitionPageFive from "./11_RecognitionPage_shi.vue";
// import PracticePageOne from "@/views/Course/Game/kids_choose-abcde.vue";
import PracticePageOne from "./12_PracticePage_one.vue";
import PracticePageTwo from "./13_PracticePage_two.vue";
import StoryPageThree from "./14_StoryPageThree.vue"; 
import RecognitionPageSix from "./15_RecognitionPage_zaijian.vue";

import PracticePageThree from "./16_PracticePage_three.vue";
import PracticePageFour from "./17_PracticePage_four.vue";
import SingPage from "./18_SingPage.vue";
import WordsCardPage from "./19_WordsCardPage.vue";

import SummaryPage from "./20_SummaryPage.vue";
import RankMapPageTwo from "./21_RankMapPageTwo.vue";
import ByePage from "./22_ByePage.vue";

export default {
  name: "Lesson5",
  mixins: [lessonMixin],
  components: {
    SyllabusPageA,
    WhiteBoard,

    LessonPage,
    RankMapPageOne,
    TargetPage,
    IntroductionPage,
    StoryPageOne,
    RecognitionPageOne,
    RecognitionPageTwo,
    StoryPageTwo,
    RecognitionPageThree,
    RecognitionPageFour,
    RecognitionPageFive,
    PracticePageOne,
    PracticePageTwo,
    StoryPageThree,
    RecognitionPageSix,
    PracticePageThree,
    PracticePageFour,
    SingPage,
    WordsCardPage,
    SummaryPage,
    RankMapPageTwo,
    ByePage,
  },
  data() {
    return {
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        IntroductionPage,
        StoryPageOne,
        RecognitionPageOne,
        RecognitionPageTwo,
        StoryPageTwo,
        RecognitionPageThree,
        RecognitionPageFour,
        RecognitionPageFive,
        PracticePageOne,
        PracticePageTwo,
        StoryPageThree,
        RecognitionPageSix,
        PracticePageThree,
        PracticePageFour,
        SingPage,
        WordsCardPage,
        SummaryPage,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson-one-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
</style>

<template>
  <div class="game-container">
    <PhoneNumberGame :callList="callList" />
  </div>
</template>

<script>
import PhoneNumberGame from "@/components/Course/GamePage/PhoneNumberGame";
export default {
  data() {
    return {
      callList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G23-phone-number/fireman.svg"),
          sound: require("@/assets/audio/L1/6-Numbers/1-1-9.mp3"),
          number: "119",
          pinying: "yī     yī    jiǔ",
          hanzi: "一一九",
          show: true,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G23-phone-number/dongdong.svg"),
          sound: require("@/assets/audio/L1/6-Numbers/1-2-5-7.mp3"),
          number: "1257",
          pinying: "yī      èr    wǔ   qī",
          hanzi: "一二五七",
          show: true,
          showLock: false,
          isPhone: true
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G23-phone-number/xiaoai.svg"),
          sound: require("@/assets/audio/L1/6-Numbers/5-8-8-5-9.mp3"),
          number: "58859",
          pinying: "wǔ    bā    bā   wǔ   jiǔ",
          hanzi: "五八八五九",
          show: true,
          isPhone: true
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G23-phone-number/friend.svg"),
          sound: require("@/assets/audio/L1/6-Numbers/7-6-5-9-9-4.mp3"),
          number: "765994",
          pinying: "qī   liù     wǔ      jiǔ    jiǔ    sì ",
          hanzi: "七六五九九四",
          show: true,
          isPhone: true
        },
      ],
    };
  },
  components: {
    PhoneNumberGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>

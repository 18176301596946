<template>
  <div class="game-container">
    <RecognitionPinyinGame
      :optionList="optionList"
      :imgList="imgList"
      :lessonId="lessonId"
      :backgroundSize="`cover`"
      :isFullScreen="true"
    />
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      lessonId: 5,
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-05-hi-bye-1.svg"),
          showObj: true,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-05-hi-bye-2.svg"),
          showObj: true,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-05-hi-bye-3.svg"),
          showObj: true,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-05-hi-bye-4.svg"),
          showObj: true,
        },
      ],

      imgList: [
        {
          id: 1,
          pinying: "nǐ hǎo",
          hanzi: "你好",
        },
        {
          id: 2,
          pinying: "zàijiàn",
          hanzi: "再见",
        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>












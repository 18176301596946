<template>
  <div class="game-container">
    <RecognitionNumberGame
      :ObjectList="ObjectList"
      :chineseList="chineseList"
    />
  </div>
</template>

<script>
import RecognitionNumberGame from "@/components/Course/GamePage/RecognitionNumberGame";
export default {
  data() {
    return {
      ObjectList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-six.svg"),
          number: 6,
          showObj: true,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-seven.svg"),
          number: 7,
          showObj: true,
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-eight.svg"),
          number: 8,
          showObj: true,
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-nine.svg"),
          number: 9,
          showObj: true,
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-ten.svg"),
          number: 10,
          showObj: true,
        },
      ],

      chineseList: [
        {
          id: 1,
          pinying: "jiǔ",
          number: 9,
          hanzi: "九",
        },
        {
          id: 2,
          pinying: "shí",
          number: 10,
          hanzi: "十",
        },
        {
          id: 3,
          pinying: "liù",
          number: 6,
          hanzi: "六",
        },
        {
          id: 4,
          pinying: "qī",
          number: 7,
          hanzi: "七",
        },
        {
          id: 5,
          pinying: "bā",
          number: 8,
          hanzi: "八",
        },
      ],
    };
  },
  components: {
    RecognitionNumberGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











